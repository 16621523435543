<template>
    <div>
        <!--begin::Body-->
        <div class="kt-login__body">
            <!--begin::Signin-->
            <div class="kt-login__form">
                <router-link to="/">
                    <img :src="siteLogo" alt="" class="logo-login" />
                </router-link>
                <div class="kt-login__title login-title">
                    <h3>{{ $t("AUTH.FORGOT.DESC") }}</h3>
                </div>

                <!--begin::Form-->
                <b-form
                    class="kt-form login-form"
                    @submit.stop.prevent="onSubmit"
                >
                    <div
                        role="alert"
                        v-bind:class="{ show: errors.length }"
                        class="alert fade alert-danger"
                    >
                        <div
                            class="alert-text"
                            v-for="(error, i) in errors"
                            :key="i"
                        >
                            {{ errors[0] }}
                        </div>
                    </div>

                    <b-form-group
                        id="email-input-group"
                        label=""
                        label-for="email-input"
                    >
                        <b-form-input
                            id="email-input"
                            name="email"
                            v-model="$v.form.email.$model"
                            :state="validateState('email')"
                            aria-describedby="email-input-live-feedback"
                            v-bind:placeholder="$t('AUTH.INPUT.EMAIL')"
                        ></b-form-input>

                        <b-form-invalid-feedback id="email-input-live-feedback">
                            {{ $t("AUTH.LOGIN.VALID_EMAIL") }}
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <div class="kt-login__actions">
                        <router-link to="/login">
                            <a class="kt-link kt-login__link-forgot">
                                {{ $t("AUTH.LOGIN.TITLE") }}
                            </a>
                        </router-link>

                        <b-button
                            type="submit"
                            id="kt_submit"
                            class="btn btn-success btn-elevate kt-login__btn-primary"
                        >
                            {{ $t("AUTH.FORGOT.BUTTON") }}
                        </b-button>
                    </div>
                    <!--end::Action-->
                    <!--end::Action-->
                </b-form>
                <!--end::Form-->
            </div>
            <!--end::Signin-->
        </div>
        <!--end::Body-->
    </div>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
    right: 8px;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import { FORGOT } from "@/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    name: "login",
    data() {
        return {
            form: {
                email: ""
            }
        };
    },
    mounted() {
        // check if current user is authenticated
        if (this.isAuthenticated) {
            this.$router.push({ name: "dashboard" });
        }
    },
    validations: {
        form: {
            email: {
                required,
                email
            }
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        resetForm() {
            this.form = {
                email: null
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        onSubmit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }



            // set spinner to submit button
            const submitButton = document.getElementById("kt_submit");
            submitButton.classList.add(
                "kt-spinner",
                "kt-spinner--light",
                "kt-spinner--right"
            );

            // send login request
            this.$store
                .dispatch(FORGOT, this.form.email)
                // go to which page after successfully login
                .then(() => {
                    this.$router.push({ name: "login" });
                })
                .finally(() => {
                    submitButton.classList.remove(
                        "kt-spinner",
                        "kt-spinner--light",
                        "kt-spinner--right"
                    );
                });
        }
    },
    computed: {
        ...mapGetters(["isAuthenticated"]),
        ...mapState({
            errors: state => state.auth.errors
        }),
        siteLogo() {
            return process.env.BASE_URL + "assets/media/logos/logo.png";
        }
    }
};
</script>
